table {
  display: table;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  margin-bottom: 2.5rem;
  box-sizing: inherit;
  border-collapse: separate;
}

tbody {
  box-sizing: inherit;
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  box-sizing: inherit;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

td {
  display: table-cell;
  vertical-align: inherit;
  box-sizing: inherit;
  border-bottom: 0.1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
  padding-left: 0;
}

.idea-table {
  max-width: 40rem;
}

.modal {
  background-color: #fefefe;
  margin: 1% auto;
  border-radius: 5px;
  border: 1px transparent;
  width: 93%; 
}

.idea-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.idea-title {
  max-width: 90%;
}

.idea-img {
  max-width: 50%;
  object-fit: cover;
}