#navbar {
  display: flex;
  overflow: visible;
  border-bottom: 5px solid var(--color-light-gray);
  /* margin-bottom: 20px; */
}

#navbar img {
  display: inline;
  height: 40px;
  vertical-align: middle;
  padding-right: 17px;
}

#navbar-buttons {
  /* max-width: var(--page-width); */
  margin: 0 auto;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
}

#navbar-buttons ul {
  margin: 0;
}

#navbar li {
  display: inline;
  font-size: 15px;
  text-decoration: none;
}

a.navbar-button {
  display: inline-block;
  padding: 15px 14px;
  color: var(--color-primary);
  text-decoration: none;
}

a:hover.navbar-button {
  background-color: var(--color-light-gray);
}

a:active.navbar-button {
  background-color: var(--color-primary);
  color: white;
}
